import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import './App.css';
import "./index.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Accommodation from './pages/accommodation/Accommodation';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';


function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="accommodation" element={<Accommodation />} />
        <Route path="contact" element={<Contact />} />        
      </Routes>
      <Footer />
    </>
  );
}

export default App;
