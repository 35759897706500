import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <Navbar expand="lg" className="bg-transparent">
      <Container className='footer'>
          <Nav className="me-auto nav">
            <Nav.Link className='link' href="/">HOME</Nav.Link>
            <Nav.Link className='link' href="accommodation">ACCOMMODATION</Nav.Link>
            <Nav.Link className='link' href="contact">CONTACT</Nav.Link>
            <Nav.Link className='link'>
              <h1>© {currentYear} Nithila Rest House  
                {/* <Nav.Link className='link-ceylon' href="https://ceylonxcorp.com/" rel='noreferrer' target="_blank">
                   CeylonX
                </Nav.Link>  */}
              </h1>
            </Nav.Link>
          </Nav>
      </Container>
    </Navbar>
  )
}

export default Footer