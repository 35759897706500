import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import "./Header.css";
// lg
function Header() {
  return (
    <Navbar expand="lg" className="bg-transparent navbar">
      <Container className="container">
        <Navbar.Brand href="/">
          <img
            className="logo"
            src="/assets/img/site-new-logo-150x150.png"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle className="res-nav" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-start justify-content-lg-end"
        >
          <Nav className="h-nav position-absolute position-lg-relative gap-lg-3">
            <Nav.Link className="link" href="/">
              HOME
            </Nav.Link>
            <Nav.Link className="link" href="accommodation">
              ACCOMMODATION
            </Nav.Link>
            <Nav.Link className="link" href="contact">
              CONTACT
            </Nav.Link>
            <Button className="btn ms-0" href="http://nithilainn.com/">
              Click Here to visit Nithila Inn
            </Button>{" "}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
