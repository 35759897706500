import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Contact.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import background from '../../assets/img/pexels-pavel-danilyuk-7658355-scaled.jpg';
import { ToastContainer, toast } from 'react-toastify';
import React, { useState, useRef } from "react";
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
  const [submitted, setSubmitted] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    
    try {
        const response = await fetch('https://formspree.io/f/mjvnqoea', {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json'
            }
        });

        if (response.ok) {
            toast.success("Thank you for contacting us!");
            // Reset the form fields
            formRef.current.reset();
            setSubmitted(true);
        } else {
            console.error('Form submission failed:', response);
            toast.error("Something Wrong! Try Again!");
            // Optionally, handle error state
        }
    } catch (error) {
        console.error('Form submission error:', error);
        toast.error("Something Wrong! Try Again!");
        // Optionally, handle error state
    }
};

  return (
    <div>
        <section className='contact'>
        <div className="contact-bg-image darken-with-text" style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '550px',
            position: 'relative',
          }}>
            <h1>CONTACT US</h1>
            </div>
        </section>

        <section className='form-container'>
            <h1>CONTACT FORM</h1>
            <Form ref={formRef} onSubmit={handleSubmit}>
                  <div className="container">
                <Row>
                    <Form.Label htmlFor="first-name" className="contact--label">
                      Name 
                      <span className='star'>*</span>
                       </Form.Label>
                    <Col>
                    <Form.Control 
                            type="text"
                            className="contact--input test-md"
                            name="first-name"
                            id="first-name"
                            required />
                            <Form.Label htmlFor="first-name" className="contact--label">
                      First 
                       </Form.Label>
                    </Col>
                    <Col>
                    <Form.Control 
                    type="text"
                    className="contact--input test-md"
                    name="last-name"
                    id="last-name"
                    required />
                    <Form.Label htmlFor="first-name" className="contact--label">
                      Last 
                       </Form.Label>
                    </Col>
                </Row><br />
                <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label htmlFor="email" className="contact--label">Email</Form.Label>
                    <Form.Control 
                    type="email"
                    className="contact--input test-md"
                    name="email"
                    id="email"
                    required />
                </Form.Group> <br />
                <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label htmlFor="message" className="contact--label">Comment or Message</Form.Label>
                    <FloatingLabel controlId="floatingTextarea2" label="">
                        <Form.Control
                         className="contact--input test-md"
                         name="message"
                         id="message"
                         rows="8"
                         placeholder="Type your message..."
                         required
                        as="textarea"
                        style={{ height: '100px' }}
                        />
                    </FloatingLabel>
                </Form.Group> <br />
                <div>
                  <button type="submit" className="btn btn-primary contact--form--btn">
                    Submit
                  </button>
                </div>
                </div>
            </Form>

            {/* <form
                action="https://formspree.io/f/xleyykpa"
                method="POST"
                className="contact--form--container"
            >
                <div className="container">
                    <label htmlFor="first-name" className="contact--label">
                        <span className="text-md">First Name</span>
                        <input
                            type="text"
                            className="contact--input test-md"
                            name="first-name"
                            id="first-name"
                            required
                        />
                    </label>
                    <label htmlFor="email" className="contact--label">
                        <span className="text-md">Email</span>
                        <input
                            type="email"
                            className="contact--input test-md"
                            name="email"
                            id="email"
                            required
                        />
                    </label>
                </div>
                <label htmlFor="message" className="contact--label">
                    <span className="text-md">Message</span>
                    <textarea
                        className="contact--input test-md"
                        name="message"
                        id="message"
                        rows="8"
                        placeholder="Type your message..."
                        required
                    />
                </label>
                <div>
                    <button type="submit" className="btn btn-primary contact--form--btn">
                        Submit
                    </button>
                </div>
            </form> */}

        </section>

        <section className='map'>
            <h1>OUR BRANCHES</h1>

            <div class="container text-center">
                <div class="row">
                <div class="col-sm text-start">                
                    <h2>Nithila inn – Kesbewa</h2>
                    <h2>228/ J, Bandaragama Road,</h2>
                    <h2>Kesbewa, Sri Lanka</h2>
                    <a rel="noopener" target="_blank" href="tel:+94 11 270 3313 , +94 77 732 5137 , +94 77 77 58486">+94 11 270 3313 / +94 77 732 5137 / +94 77 77 58486</a>
                </div>
                <div class="col-sm text-start">
                    <h2>Nithila Rest House – Katharagama</h2>
                    <h2>Kotugoda Dammawasha Nahimi Mawatha,</h2>
                    <h2>Maligama, Katharagama.</h2>
                    <a rel="noopener" target="_blank" href="tel:+94 77 7758 489 , +94 72 363 2036 , +94 77 732 5137">+94 77 7758 489/+94 72 363 2036/+94 77 732 5137</a>
                </div>
                </div>
            </div>
            
            <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-c4ddac5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c4ddac5" data-element_type="section">
				<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2ca2d2b" data-id="2ca2d2b" data-element_type="column">
			            <div class="elementor-widget-wrap elementor-element-populated">
						    <div class="elementor-element elementor-element-f65bf27 elementor-widget elementor-widget-google_maps" data-id="f65bf27" data-element_type="widget" data-widget_type="google_maps.default">
				                <div class="elementor-widget-container">
			                        <div class="elementor-custom-embed">
			                        <iframe loading="lazy" src="https://maps.google.com/maps?q=Nithila%20Rest%2C%20Kataragama-Sella%20Kataragama%20Rd%2C%20Kataragama&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" title="Nithila Rest, Kataragama-Sella Kataragama Rd, Kataragama" aria-label="Nithila Rest, Kataragama-Sella Kataragama Rd, Kataragama"></iframe>
		                            </div>
				                </div>
				            </div>
					    </div>
		            </div>
			    </div>
		    </section>
        </section>
        <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                backgroundColor: '#262437',
                color: '#ffffff'
                }}
            />
    </div>
  )
}

export default Contact