import Image from 'react-bootstrap/Image';
import './Home.css';
import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import background from '../../assets/img/7-C.jpg';

function Home() {

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const images = [
    '/assets/img/g1.png',
    '/assets/img/g2.png',
    '/assets/img/g3.png',
    '/assets/img/g4.png',
  ];
  return (
    <div>
        <section className='home'>
          <div className="home-bg-image darken-with-text" style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              position: 'relative',
            }}>
            <h1>NITHILA</h1>
            <h2>REST HOUSE</h2>
            <p>Relax and Recharge, Your Idyllic Getaway at Our Restful Retreat House.</p>
            </div>
        </section>

        <section className='img'>
            <h1>Nithila Rest's Visual Delights</h1>
          
            <div className="carousel-container">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index} className="carousel-slide">
                    <img src={image} alt={`Slide ${index}`} />
                  </div>
                ))}
              </Slider>
            </div>
        </section>
          
        <section className='about'>
          <div class="container text-center">
            <div class="row">
              <div class="col-sm">                
                <Image className="img2" src="/assets/img/01.png" fluid />
              </div>
              <div class="col-sm text-start">
                <h1>Your Retreat in Sacred</h1>
                <h1>Katharagama</h1>
                <p>Nithila Rest, nestled in the heart of Katharagama, <br />invites you to a tranquil stay just moments away from the revered Kataragama Temple. </p>
                <p>Immerse yourself in the spiritual aura of this sacred town, and find solace in our cozy accommodations, making your visit to Katharagama a truly enriching experience.</p>                    
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default Home